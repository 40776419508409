<script>
import useVuelidate from "@vuelidate/core";
import { required, minLength, maxLength, helpers } from "@vuelidate/validators";
import CryptoJS from "crypto-js";
import Layout from "../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import "@vueform/multiselect/themes/default.css";
import "flatpickr/dist/flatpickr.css";
// import Swal from "sweetalert2";
import Multiselect from "@vueform/multiselect";
import "@vueform/multiselect/themes/default.css";

export default {
  setup() {
    return { v$: useVuelidate() };
  },

  data() {
    return {
      data: {
        role: "etudiant",
      },
      teachers: [],
      classTeacher: null,
      students: [],
      allStudents: [],
      fetchedStudents: [],
      selectedStudents: [],
      studentGrade: [],
      semestre: "1",
      page: 1,
      perPage: 5,
      pages: [],
      trimestres: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
      ],
      searchQuery: null,
      submited: false,
      admitSpan: false,
      title: "Filière",
      items: [
        {
          text: "Filières",
          href: "/",
        },
        {
          text: "Modifier titulaire",
          active: true,
        },
      ],
    };
  },

  validations: {
    data: {
      annee_formation: {
        required: helpers.withMessage("Veuillez faire un choix", required),
        maxLength: helpers.withMessage("1 characteres maximum", maxLength(1)),
      },
      nom_filiere: {
        required: helpers.withMessage(
          "Veuillez saisir le nom de la filiere",
          required
        ),
        minLength: helpers.withMessage("8 characteres minimum", minLength(8)),
        maxLength: helpers.withMessage("50 characteres maximum", maxLength(50)),
      },
      niveau_formation: {
        required: helpers.withMessage("Veuillez faire un choix", required),
      },
      code_filiere: {
        required: helpers.withMessage(
          "Veuillez saisir le code de la filiere",
          required
        ),
        minLength: helpers.withMessage("2 characteres minimum", minLength(2)),
        maxLength: helpers.withMessage("7 characteres maximum", maxLength(7)),
      },
      montant_ecolage: {
        required: helpers.withMessage(
          "Veuillez saisir le montant de l'ecolage (FCFA)",
          required
        ),
        minLength: helpers.withMessage("5 characteres minimum", minLength(5)),
        maxLength: helpers.withMessage("8 characteres maximum", maxLength(8)),
      },
    },
  },
  components: {
    Layout,
    PageHeader,
    Multiselect,
  },
  mounted() {
    this.decryptFiliere();
    this.getFacultyStudents();
    this.getAllStudents();
  },
  computed: {
    displayedPosts() {
      return this.paginate(this.students);
    },
    resultQuery() {
      if (this.searchQuery) {
        const search = this.searchQuery.toLowerCase();
        return this.displayedPosts.filter((data) => {
          return (
            data.id.toLowerCase().includes(search) ||
            data.name.toLowerCase().includes(search) ||
            data.email.toLowerCase().includes(search) ||
            data.position.toLowerCase().includes(search) ||
            data.company.toLowerCase().includes(search) ||
            data.country.toLowerCase().includes(search)
          );
        });
      } else {
        return this.displayedPosts;
      }
    },
  },
  watch: {
    posts() {
      this.setPages();
    },
  },
  filters: {
    trimWords(value) {
      return value.split(" ").splice(0, 20).join(" ") + "...";
    },
  },
  methods: {
    initFacultyCreation() {
      this.submited = true;
      this.updateFiliere();
    },

    getFacultyStudents() {
      const self = this;
      const faculty = this.data.id;
      this.$store
        .dispatch("getRequest", {
          route: `api/inscrits/${faculty}`,
          data: this.data,
        })
        .then(
          function (response) {
            self.students = response.data.students;
            self.setPages();
          },
          function (error) {
            console.log(error);
          }
        );
    },

    getAllStudents() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: "api/user/etudiant ",
          data: this.data,
        })
        .then(
          function (response) {
            self.allStudents = response.data.searchedUsers;
            self.fetchedStudents = response.data.searchedUsers;
          },
          function (error) {
            console.log(error);
          }
        );
    },
    getTeachers() {
      const self = this;
      this.$store
        .dispatch("getRequest", {
          route: "api/user/professeur ",
          data: this.data,
        })
        .then(
          function (response) {
            response.data.searchedUsers.forEach((element) => {
              self.teachers.push({
                value: element.id,
                label: element.nom + " " + element.prenom,
              });
            });
          },
          function (error) {
            console.log(error);
          }
        );
    },
    decryptFiliere() {
      const encryptedMessage1 = localStorage.getItem("dataF");
      const encryptionKey1 = "Slim#9065";
      const decrypted1 = CryptoJS.AES.decrypt(
        encryptedMessage1,
        encryptionKey1
      );
      const decryptedMessage1 = decrypted1.toString(CryptoJS.enc.Utf8);
      this.data = JSON.parse(decryptedMessage1);
      this.classTeacher =
        this.data?.department_professors_history[0]?.professeur?.id;
    },
    setPages() {
      let numberOfPages = Math.ceil(this.students.length / this.perPage);
      for (let index = 1; index <= numberOfPages; index++) {
        this.pages.push(index);
      }
    },
    paginate(data) {
      let page = this.page;
      let perPage = this.perPage;
      let from = page * perPage - perPage;
      let to = page * perPage;
      return data.slice(from, to);
    },
    getStudentGrades(student) {
      this.data.student_id = student;
      this.$store
        .dispatch("getRequest", {
          route: `api/getStudentGrades/${this.data.student_id}/${this.semestre}`,
          data: this.data,
        })
        .then((response) => {
          this.studentGrade = response.data;

          this.studentGrade.forEach((grade) => {
            const devoir = grade.notes.devoir
              ? parseFloat(grade.notes.devoir)
              : 0;
            const compo = grade.notes.compo ? parseFloat(grade.notes.compo) : 0;
            const count = (devoir ? 1 : 0) + (compo ? 1 : 0);

            grade.moyenne = count > 0 ? (devoir + compo) / count : null;

            if (grade.moyenne !== null) {
              if (grade.moyenne >= 16) {
                grade.appreciation = "Très bien";
              } else if (grade.moyenne >= 14) {
                grade.appreciation = "Bien";
              } else if (grade.moyenne >= 12) {
                grade.appreciation = "Assez bien";
              } else if (grade.moyenne >= 10) {
                grade.appreciation = "Passable";
              } else {
                grade.appreciation = "Insuffisant";
              }
            } else {
              grade.appreciation = "Aucune note disponible";
            }
          });
        })

        .catch((error) => console.log(error));
    },

    downloadPDF() {
      this.$store
        .dispatch("mediaGetRequest", {
          route: `api/downloadStudentGrades/${this.data.student_id}/${this.semestre}`,
          data: this.data,
        })
        .then((response) => {
          const url = window.URL.createObjectURL(
            new Blob([response.data], { type: "application/pdf" })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "releve-de-notes.pdf"); // Nom du fichier
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.multiselect-listbox {
  display: flex;
  gap: 1rem;
}

.listbox,
.selected-listbox {
  padding: 1rem;
  width: 100%;
  height: 350px;
}

.listbox .list__items,
.selected-listbox .list__items {
  border: 1px solid #ccc;
  margin-top: 13px;
  height: 100%;
  margin-left: 0rem;
  flex-basis: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.listbox .list__items .list__item,
.selected-listbox .list__items .list__item {
  font-size: 0.9em;
  padding: 7px 10px;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
  list-style: none;
  cursor: pointer;
}

.list__item:hover {
  background-color: #ccc;
}

.buttons {
  cursor: pointer;
  color: #3eaf7c;
  font-weight: 500;
  text-decoration: none;
}

.list__actions i {
  font-size: x-large;
  font-weight: 900;
}
</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="col-md-12">
      <div class="card">
        <div class="card-body p-4">
          <div class="row justify-content-end mb-3">
            <div class="col-lg-4 mb-3">
              <label for="fac" class="form-label"
                >Choisisez le trimestre/semestre pour voir les notes :</label
              >
              <Multiselect
                class="form-control"
                id="fac"
                v-model="semestre"
                :close-on-select="true"
                :searchable="true"
                :show-labels="false"
                :options="trimestres"
              />
            </div>
          </div>
          <div class="table-responsive table-card">
            <table class="table align-middle table-nowrap" id="customerTable">
              <thead class="table-light text-muted">
                <tr>
                  <th class="sort" data-sort="currency_name" scope="col">
                    Matricule
                  </th>
                  <th class="sort" data-sort="current_value" scope="col">
                    Nom
                  </th>
                  <th class="sort" data-sort="current_value" scope="col">
                    Prenom
                  </th>
                  <th class="sort" data-sort="high" scope="col">Sexe</th>
                  <th scope="col">Action</th>
                </tr>
              </thead>
              <!--end thead-->
              <tbody class="list form-check-all">
                <tr v-for="(data, index) of resultQuery" :key="index">
                  <td class="id">{{ data.matricule }}</td>
                  <td>{{ data.nom }}</td>
                  <td>{{ data.prenom }}</td>
                  <td>{{ data.sexe.toUpperCase() }}</td>
                  <td>
                    <div class="hstack gap-3 flex-wrap">
                      <a
                        href="javascript:void(0);"
                        data-bs-toggle="modal"
                        title="Notes de l'étudiant"
                        data-bs-target="#exampleModalgrid"
                        @click="getStudentGrades(data.id)"
                        class="ml-3 link-success fs-15"
                        ><i class="ri-eye-line"></i
                      ></a>
                    </div>
                  </td>
                </tr>
              </tbody>
              <!--end tbody-->
            </table>
          </div>
          <div
            class="modal fade"
            id="exampleModalgrid"
            tabindex="-1"
            aria-labelledby="exampleModalgridLabel"
            aria-modal="true"
          >
            <div class="modal-dialog modal-dialog-centered modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="myLargeModalLabel">
                    Relevé de Notes du Trimestre/Semeste {{ semestre }}
                  </h5>
                  <button
                    type="button"
                    class="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  ></button>
                </div>
                <div class="modal-body card">
                  <div class="card-body">
                    <div class="table-responsive table-card">
                      <table
                        class="table align-middle table-nowrap"
                        id="customerTable"
                      >
                        <thead class="table-light text-muted">
                          <tr>
                            <th scope="col">Matiere</th>
                            <th scope="col">Coeffiscient</th>
                            <th scope="col">Type de Matiere</th>
                            <th scope="col">Note de Devoir</th>
                            <th scope="col">Note de Composition</th>
                            <th scope="col">Moyenne</th>
                            <th scope="col">Appréciation</th>
                            <th scope="col">professeur</th>
                          </tr>
                        </thead>
                        <tbody class="list form-check-all">
                          <tr v-for="note in studentGrade" :key="note.id">
                            <th scope="row">{{ note.nom }}</th>
                            <th scope="row">{{ note.coeff }}</th>
                            <th scope="row">{{ note.type_ue }}</th>
                            <th scope="row">{{ note.notes?.devoir }}</th>
                            <th scope="row">{{ note.notes?.compo }}</th>
                            <th scope="row">{{ note.moyenne }}</th>
                            <th scope="row">{{ note.appreciation }}</th>
                            <th scope="row">{{ note.professeur }}</th>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div class="row justify-content-center mb-3">
                  <div class="col-md-3">
                    <button class="btn btn-success" @click="downloadPDF"> <i class="ri-arrow-down-line"></i> Télécharger </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-3">
            <div class="pagination-wrap hstack gap-2">
              <a
                class="page-item pagination-prev disabled"
                href="#"
                v-if="page != 1"
                @click="page--"
              >
                Previous
              </a>
              <ul class="pagination listjs-pagination mb-0">
                <li
                  :class="{
                    active: pageNumber == page,
                    disabled: pageNumber == '...',
                  }"
                  v-for="(pageNumber, index) in pages.slice(page - 1, page + 5)"
                  :key="index"
                  @click="page = pageNumber"
                >
                  <a class="page" href="#">{{ pageNumber }}</a>
                </li>
              </ul>
              <a
                class="page-item pagination-next"
                href="#"
                @click="page++"
                v-if="page < pages.length"
              >
                Next
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
